<template>
<div class="center-container">
  <div class="container">

    <div class="flex flex-row min-h-full min-w-full justify-between">
      <div class="thumb-logo flex flex-col mx-5 md:mx-8 my-6">
        <LogoHorizontal :scale="0.8"/>
            <AboutModal class="about-modal" v-if="aboutModalActive" :cancel-callback="closeAbout"/>
            <ShareModal class="share-modal" v-if="shareModalActive" :cancel-callback="closeShare"/>
      </div>
          
      <div class="utilities flex flex-row mx-5 md:mx-8">
        <AboutButton class="links my-7" :click="about"/>
        <ShareButton class="links my-7" :click="share"/>
      </div>
    </div>

      <!-- The Heading Paragraph -->

    <div class="flex flex-row min-h-full min-w-full justify-center">
        <div class="flex flex-col">
        <h1 class="heading mx-7">START A POLL AND LET EVERYONE VOTE</h1>
        <p class="proj-info mx-4 py-6">
          QR Thumbs lets you create polls for any purpose. Simply start
          a new session below and ask your participants to scan the QR code displayed on the screen with their phones’ camera app. They can vote or reset the poll on their mobile device and see the live results on your screen.
        </p>
        </div>
    </div>

    <!-- The 3 Squares -->

    <div class="squares flex flex-col md:flex-row min-h-full min-w-full items-center lg:justify-center space-y-4 md:space-y-0 md:space-x-4 ">
        <div class="square flex flex-col justify-end items-center p-6">
            <StepOneIcon class="steps"/>
            <h5 class="step-title pt-4">STEP ONE</h5>
            <p>Start a new session on the web with a QR code for voting</p>
        </div>
        <div class="square flex flex-col justify-end items-center p-6">
          <StepTwoIcon/>
          <h5 class="step-title">STEP TWO</h5>
          <p>Participants scan the code with their camera app</p>
        </div>
        <div class="square flex flex-col justify-end items-center p-6">
          <StepThreeIcon/>
          <h5 class="step-title">STEP THREE</h5>
          <p>Vote on the mobile site and see live results on the big screen</p>
        </div>
    </div>

    <div class="flex flex-row min-h-full min-w-full justify-center">
      <NewSessionModal class="reset-modal" v-if="newSessionModalActive" :cancel-callback="cancelNewSessionWarning" />
        <div class="flex flex-col m-14">
          <!-- <button @click="createSession()" class="text-black">Start a new session</button> -->
          <button @click="newSessionWarning()" class="text-black">Start a new session</button>
          <p class="mt-16">QRThumbs is a <a class="link" href="http://justshipped.io">Just Shipped</a> Project.<br>Copyright © 2020. All rights reserved.</p>
        </div>
    </div>

  </div>

  <!-- <div class="row">
      <div class="col">
        <div class="thumbUp">
          <ThumbsUpBlack/>
        </div>
      </div>
    </div> -->

</div>
</template>

<script>
import LogoHorizontal from '../components/svgs/LogoHorizontal.vue'
import StepOneIcon from '../components/svgs/StepOneIcon.vue';
import StepTwoIcon from '../components/svgs/StepTwoIcon.vue';
import StepThreeIcon from '../components/svgs/StepThreeIcon.vue';
import ShareModal from '../components/modals/ShareModal.vue'
import AboutModal from '../components/modals/AboutModal.vue'
import ShareButton from '../components/desktop/ShareButton.vue'
import AboutButton from '../components/desktop/AboutButton.vue'
import NewSessionModal from '../components/modals/NewSessionModal.vue'

export default{
  components: {
    LogoHorizontal,
    StepOneIcon,
    StepTwoIcon,
    StepThreeIcon,
    ShareModal,
    AboutModal,
    ShareButton,
    AboutButton,
    NewSessionModal,
  },
  name: 'Landing',
  data(){
    return{
      shareModalActive: false,
      aboutModalActive: false,
      newSessionModalActive: false,
    }
  },
  methods:{
    generateRandomSessionId() {
      return Array(8)
        .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
        .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)])
        .join('');
    },
    createSession() {
      this.$router.push(`/host/${this.generateRandomSessionId()}`)
    },
    about(){
      this.aboutModalActive = true;
    },
    share(){
      this.shareModalActive = true;
    },
    closeShare() {
      this.shareModalActive = false;
    },
    closeAbout() {
      this.aboutModalActive = false;
    },
    newSessionWarning() {
      if(window.innerWidth < 1024){
        this.newSessionModalActive = true;
      }else{
        this.createSession();
      }
    },
    cancelNewSessionWarning(){
      this.newSessionModalActive = false;
    }

  }
}
</script>


<style scoped>

h1, h5, p{
    background: -webkit-linear-gradient(#c4c2c2, #a1a1a1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*Color gradient used for the text*/
.center-container{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 1px;
}

.container{
  z-index: 1;
}

.square{
  height: 287px;
  width: 274px;
  background-color: #404040;
  border-radius: 6px;
}

.links{
  height: 30px;
  width: 62px;
  border-radius: none;
}


.heading{
  margin-top: 38px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 1px;
  text-align: center;
}

.proj-info{
  /* margin: 1.5em 4em 3em 4em; */
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
}

.step-title{
  line-height: 50px;
}

p{
  font-size: 14px;
}

.link{
  border-bottom: 1px solid lightgray;
}

button{
  /* Rectangle 56 */
  width: 284px;
  height: 50px;
  /* gray-gradient-light */
  background: linear-gradient(180deg, #CFCFCF 0%, #FFFFFF 100%);
  border-radius: 40px;
  color: #404040;
}

.thumbUp{
  position: fixed;
  bottom: 100px;
  left: 48px;
  z-index: 2;
}


.share-modal >>> .modal-card, .about-modal >>> .modal-card{
  max-width: 620px !important;
}

  /*Responsive settings*/

  @media (max-height: 568px) { /*iPhone 5*/
  .squares{
      align-items: center;
    }

  .steps{
    height: 30px;
  }
  .utilities{
    margin: 0;
  }
  .thumb-logo{
    margin-right: 0;
    margin-left: 7px;
  }
}


</style>
