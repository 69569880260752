<template>
  <div>
    <svg class="close" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 17L1 1" stroke="#D3D3D3" stroke-width="2" stroke-linejoin="round"/>
<path d="M17 1L1 17" stroke="#D3D3D3" stroke-width="2" stroke-linejoin="round"/>
</svg>

  </div>
</template>

<script>
export default{
  name:'CloseIcon',
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>

/* Responsive settings*/
@media (max-height: 568px) { /*iPhone 5*/
  .close{ width: 12px; }
}
@media (max-height: 667px) { /*iPhone 6*/
  .close{ width: 14px; }
} 
@media (max-height: 812px) { /*iPhone X*/
 .close{ width: 15px; }
} 

</style>
