<template>
  <div class="flex flex-col
      lg:flex-row lg:min-h-full lg:min-w-full lg:justify-around items-center">
    <div class="flex flex-col">
        <!-- <img src="../assets/Images/qr-logo-mini.svg" alt="qr logo" /> -->
        <Logo :scale="0.8"/>
        <!-- <a href="">About</a> -->
        <!-- <a href="">Share</a> -->
    </div>

    <div class="flex flex-row">
    <Scoreboard :voted="hasVoted" :votes="upvotes" caption="Yes">
        <ThumbsUpIcon :scale="2" />
      </Scoreboard>

      <Scoreboard :voted="hasVoted" :votes="downvotes" caption="No">
      <ThumbsDownIcon :scale="2" />
    </Scoreboard>
    </div>

    <div class="flex flex-col items-center">
      <h1 class="vote-now">VOTE NOW </h1>
      <div>
      <qrcode :value="pollLink" :scale="8" />
      </div>
      <p class="vote-now-desc mt-2" style="max-width: 400px">Scan this code with your camera app</p>
    </div>
  </div>
</template>

<script>
import Logo from '../components/svgs/Logo.vue'
import ThumbsUpIcon from "../components/svgs/ThumbsUpIcon.vue";
import ThumbsDownIcon from "../components/svgs/ThumbsDownIcon.vue";
import { subscribe, onEvent, emitEvent } from "../services/ThumbSocket";
import QrcodeVue from "vue3-qrcode";
import Scoreboard from '../components/desktop/Scoreboard.vue';

export default {
  components: {
    Logo,
    Scoreboard,
    ThumbsUpIcon,
    ThumbsDownIcon,
    qrcode: QrcodeVue,
  },
  name: "Desktop",
  data() {
    return {
      // voteId: new Date().getTime(),
      upvotes: 0,
      downvotes: 0,
      hasVoted: false,
      resetModalActive: false,
      pollId: this.$route.params.id,
      round: 1 // XXX TODO restore from session storage?
    };
  },
  computed: {
    totalUpvotes() {
      return this.upvotes;
    },
    totalDownvotes() {
      return this.downvotes;
    },
    pollLink() {
      return `${window.origin}/vote/${this.$route.params.id}`;
    },
  },
  methods: {
    upvote() {
      this.upvotes++; //to increment the desktop inner counter.
      this.hasVoted = true;
      // emitEvent("upvote"); //to emit the vote for vote and tv counters.
      this.sendUpdate()
    },
    downvote() {
      this.downvotes++;
      this.hasVoted = true;
      // emitEvent("downvote");
      this.sendUpdate()
    },
    sendUpdate() {
      emitEvent("current", {
        upvotes: this.upvotes,
        downvotes: this.downvotes,
        round: this.round,
        pollId: this.pollId
        // voteId: this.voteId
      });
    },
  },
  mounted() {
    subscribe(this.$route.params.id).then(() => {
      onEvent("upvote", () => {
        this.upvotes++;
        this.sendUpdate();
      });

      onEvent("downvote", () => {
        this.downvotes++;
        this.sendUpdate();
      });

      onEvent("reset", () => {
        this.downvotes = 0;
        this.upvotes = 0;
        this.round += 1
        // this.voteId = new Date().getTime();
        this.sendUpdate();
      });

      // We will continue to broadcast latest state in case someone falls out of sync.
      setInterval(() => this.sendUpdate(), 2500);
    });
  },
};
</script>

<style scoped>

.vote-now {
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 61.474px;
line-height: 92px;
text-align: center;
letter-spacing: 2.18907px;
text-transform: uppercase;
}

.vote-now-desc {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 48px;
text-align: center;
}

/*Responsive settings*/

@media (max-height: 469px) { 
    .vote-now{
      font-size: 36px;
    }
  }

  @media (min-height: 470px) { /*iPhone 5*/
    .vote-now{
      font-size: 36px;
    }
    .vote-now-desc{
      font-size: 24px;
    }
  }

  @media (min-height: 813px) { /*iPad*/
   .vote-now{
      font-size: 61.474px;
    }
    .vote-now-desc{
      font-size: 32px;
    }
  }

</style>
