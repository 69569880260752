<template>
  <button class="flex items-center justify-center border rounded-full" @click="click">
    <ResetIcon />
  </button>
</template>

<script>

import ResetIcon from '../svgs/ResetIcon.vue'

export default{
  components: { ResetIcon },
  name: 'ResetButton',
  props: {
    click: {
      type: Function,
      default: () => ({})
    }
  },
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
button{
  background: #404040;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  height: 90px;
  width: 90px;
}

button:focus{
    outline: none;
}

@media (max-height: 469px) { 
    button{
      height: 50px; 
      width: 50px;
    }
  }

@media (min-height: 470px) {
    button{
      height: 65px; 
      width: 65px;
    }
  }

  @media (min-height: 667px) {
    button{
      height: 80px; 
      width: 80px;
    }
  }

  @media (min-height: 812px) {
    button{
      height: 100px; 
      width: 100px;
    }
  }

  @media (min-height: 1024px) {
    button{
      height: 120px; 
      width: 120px;
    }
  }
</style>
