<template>
    <BaseModal>
        <div class="py-3">
            <h1 class="py-2">Sorry, you can't start a new session on mobile</h1>
            <p class="py-2"> Please access this website on a desktop computer or Smart TV.</p>
        </div>
        <div class="py-2">
            <div class="button-modal">
                <button class="pt-3 pb-1" @click="cancelCallback">Got it</button>
            </div>
        </div>
    </BaseModal>
    
</template>

<script>

import BaseModal from './BaseModal.vue'

export default {
    name: 'NewSessionModal',
    components: { BaseModal },
    props: {
        cancelCallback: {
            type: Function,
            default: () => { alert ('must implement callback') }
        },
    },
    methods: {
        cancel() {
            alert('Hey! implement some cancel')
        }
    }
}
</script>

<style scoped>

h1{
    font-size: 24px;
    font-weight: 700;
}

.grid{
    margin-top: 15px;
    border: 1px solid white;
}

p{
    padding: 0 20px;
}

.button-modal{
    border-top: 0.5px solid lightgray;
}

@media (min-height: 524px) { /*iPhone 6*/
  h1{ padding: 0 20px 0 20px; } 
}
</style>