<template>
  <button class="bg-buttongray flex flex-col items-center justify-center border rounded-full" @click="click()" :disabled="voted">
    <slot/>
    <span v-if="voted"> {{ votes }} </span>
    <span v-if="!voted"> {{ caption }} </span>
  </button>

</template>

<script>
export default{
  name: 'VoteButton',
  props: {
    caption: {
      type: String,
      default: 'Missing Caption'
    },
    click: {
      type: Function,
      default: () => alert("You have clicked but nothing happened"),
    },
    voted: {
     type: Boolean,
     default: false,
     required: true,
    },
  votes: {
      type: Number,
      default: 99999,
    }
  }
}
</script>

<style scoped>
  button{
    height: 150px; 
    width: 150px;
    background: #404040;
    outline: none;
    border: none;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  }

  span{
    padding-top: 0.2em;
    font-size: 23px;
    font-weight: bold;
    font-style: normal;
    line-height: 35px;
    color: #858585;
    /*Color gradient used for the text*/
    background: -webkit-linear-gradient(#c4c2c2, #666666);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
  }

  button:focus{
    outline: none;
  }
  button:disabled{
    background: none;
    box-shadow: none;
  }

  /*MEDIA QUERIES*/

  @media (max-height: 469px) { 
    button{
      height: 100px; 
      width: 100px;
    
    }
    span{
      font-size: 14px;
      line-height: 14px;
    }
  }

  @media (min-height: 470px) { /*iPhone 5*/
    button{
      height: 140px; 
      width: 140px;
    }
  }

  @media (min-height: 667px) { /*iPhone 6*/
    button{
      height: 180px; 
      width: 180px;
    }
  }

  @media (min-height: 812px) { /*iPhone X*/
    button{
      height: 210px; 
      width: 210px;
    }
  }

  @media (min-height: 812px) { /*iPad*/
    button{
      height: 270px; 
      width: 270px;
      font-size: 32px;
    }

    span{
      font-size: 32px;
    }
  }


</style>
