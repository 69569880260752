<template>
  <div>
    <button @click="click">
      <CloseIcon />
    </button>
  </div>
</template>

<script>

import CloseIcon from '../svgs/CloseIcon.vue'

export default{
   components: { CloseIcon },
  name:'CloseButton',
  props: {
    click: {
      type: Function,
      default: () => ({})
    }
  },
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
button{
  border: none;
  background: none !important;
}
button.links{
  color: none;
  outline: none;
}
</style>
