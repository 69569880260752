<template>
  <div :style="{opacity: disabled ? 0.5 : 1}">
    <svg :width="89 * scale" :height="85 * scale" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M86.0553 40.3604C87.5637 38.852 88.4329 36.8067 88.4329 34.6118C88.4329 31.4453 86.6214 28.666 83.9225 27.3074C84.4849 26.1862 84.7807 24.9481 84.7807 23.6552C84.7807 20.4852 82.9656 17.7058 80.2666 16.3472C81.1029 14.6818 81.3476 12.7644 80.9276 10.8616C80.1096 7.16203 76.6291 4.48126 72.6518 4.48126H39.1284C36.7325 4.48126 32.9708 5.23727 29.998 6.6397V45.7839L30.4362 46.0066L40.9545 68.7926V81.1771C40.9545 81.7321 41.2065 82.258 41.6374 82.605C41.6401 82.607 41.644 82.61 41.6492 82.614C41.8763 82.7875 44.5494 84.8292 48.2588 84.8292C52.4844 84.8292 59.2153 78.1421 59.2153 70.2205C59.2153 65.2244 57.4258 59.0048 56.3083 55.6119H78.8678C83.7835 55.6119 87.9653 51.9743 88.3926 47.3323C88.6301 44.7284 87.7609 42.2266 86.0553 40.3604ZM0.780518 39.1771C0.780518 44.2098 4.87827 48.3075 9.91092 48.3075H26.3457V2.67729C24.8154 1.52309 22.9237 0.829224 20.8675 0.829224H9.91092C4.87827 0.829224 0.780518 4.92698 0.780518 9.95963V39.1771Z"/>
</mask>
<g filter="url(#down_filter)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M86.0553 40.3604C87.5637 38.852 88.4329 36.8067 88.4329 34.6118C88.4329 31.4453 86.6214 28.666 83.9225 27.3074C84.4849 26.1862 84.7807 24.9481 84.7807 23.6552C84.7807 20.4852 82.9656 17.7058 80.2666 16.3472C81.1029 14.6818 81.3476 12.7644 80.9276 10.8616C80.1096 7.16203 76.6291 4.48126 72.6518 4.48126H39.1284C36.7325 4.48126 32.9708 5.23727 29.998 6.6397V45.7839L30.4362 46.0066L40.9545 68.7926V81.1771C40.9545 81.7321 41.2065 82.258 41.6374 82.605C41.6401 82.607 41.644 82.61 41.6492 82.614C41.8763 82.7875 44.5494 84.8292 48.2588 84.8292C52.4844 84.8292 59.2153 78.1421 59.2153 70.2205C59.2153 65.2244 57.4258 59.0048 56.3083 55.6119H78.8678C83.7835 55.6119 87.9653 51.9743 88.3926 47.3323C88.6301 44.7284 87.7609 42.2266 86.0553 40.3604ZM0.780518 39.1771C0.780518 44.2098 4.87827 48.3075 9.91092 48.3075H26.3457V2.67729C24.8154 1.52309 22.9237 0.829224 20.8675 0.829224H9.91092C4.87827 0.829224 0.780518 4.92698 0.780518 9.95963V39.1771Z" fill="url(#paint0_linear)"/>
</g>
<defs>
<filter id="down_filter" x="0.780518" y="0.829224" width="87.6524" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
</filter>
<linearGradient id="paint0_linear" x1="44.6067" y1="84.8292" x2="44.6067" y2="0.829224" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>
  </div>
</template>

<script>
export default {
  name: "ThumbsDownIcon",
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
@media (max-height: 469px) {
  svg {
    height: 40px;
    width: 40px;
  }
}

@media (min-height: 470px) {
  svg {
    height: 65px;
    width: 65px;
    padding-top: 0.5em;
  }
}

@media (min-height: 667px) {
  svg {
    height: 80px;
    width: 80px;
  }
}

@media (min-height: 812px) {
  svg {
    height: 90px;
    width: 90px;
  }
}

@media (min-height: 1024px) {
  svg {
    height: 120px;
    width: 120px;
  }
}
</style>
