import {BEND, BendSocket} from "./BendSocket";

let events = new Set();
let channel;
let socket;

const awaitLogin = new Promise((resolve) => {
    socket = new BendSocket({
        onOpen() {
            socket.logInToAppWithCredentials(BEND.APP_KEY, 'pusher', 'pusher')
        },
        async onClose() {
            (await awaitLogin)();
        },
        onMessage(message) {

            if (message.id === 'login') {
                resolve();
            }

            events.forEach((e) => {
                if (message.event === e.eventName) e.callback(message)
            })

        }
    });
});

function getChannelById(channelId) {
    return `poll-${channelId}`
}

export async function subscribe(channelId) {
    await awaitLogin;
    channel = getChannelById(channelId);
    socket.subscribe(channel);
}

export function unsubscribe(channelId) {
    channel = null;
    socket.unsubscribe(getChannelById(channelId))
}

export function onEvent(eventName, callback) {
    let e = {eventName, callback}
    events.add(e)
    return () => events.delete(e);
}

export async function emitEvent(event, data) {
    await awaitLogin;
    data = data || {};
    console.debug(`emitting event [${event}] with payload: `, data)
    socket.push(channel, event, data)
}
