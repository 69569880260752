<template>
  <div>
    <svg width="72" height="123" viewBox="0 0 72 123" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.5772 121H12.0364C6.48997 121 2 116.544 2 111.04V11.9604C2 6.45595 6.48997 2 12.0364 2H59.5772C65.1237 2 69.6136 6.45595 69.6136 11.9604V111.04C69.6136 116.544 65.1237 121 59.5772 121Z" stroke="url(#paint0_linear)" stroke-width="3.41333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.8833 12.4846H42.6738" stroke="url(#paint1_linear)" stroke-width="3.41333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.79248 100.031H68.8214" stroke="url(#paint2_linear)" stroke-width="3.41333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="36.4736" cy="110.588" rx="4.49657" ry="4.4625" fill="url(#paint3_linear)"/>
<path d="M52.3343 44.8567H45.9486L44.4273 40.8088C44.3222 40.5318 44.1296 40.2923 43.8758 40.1232C43.622 39.954 43.3194 39.8634 43.0093 39.8636H28.6038C27.9699 39.8636 27.4018 40.2426 27.1905 40.8088L25.6645 44.8567H19.2788C17.2034 44.8567 15.5225 46.4526 15.5225 48.4231V68.7518C15.5225 70.7222 17.2034 72.3182 19.2788 72.3182H52.3343C54.4097 72.3182 56.0906 70.7222 56.0906 68.7518V48.4231C56.0906 46.4526 54.4097 44.8567 52.3343 44.8567ZM52.71 68.7518C52.71 68.9479 52.5409 69.1084 52.3343 69.1084H19.2788C19.0722 69.1084 18.9031 68.9479 18.9031 68.7518V48.4231C18.9031 48.2269 19.0722 48.0664 19.2788 48.0664H28.0451L28.848 45.9355L29.9232 43.0734H41.6852L42.7604 45.9355L43.5633 48.0664H52.3343C52.5409 48.0664 52.71 48.2269 52.71 48.4231V68.7518ZM35.8066 50.9196C31.6558 50.9196 28.2939 54.1115 28.2939 58.0525C28.2939 61.9934 31.6558 65.1853 35.8066 65.1853C39.9573 65.1853 43.3192 61.9934 43.3192 58.0525C43.3192 54.1115 39.9573 50.9196 35.8066 50.9196ZM35.8066 62.3322C33.318 62.3322 31.299 60.4152 31.299 58.0525C31.299 55.6897 33.318 53.7727 35.8066 53.7727C38.2951 53.7727 40.3141 55.6897 40.3141 58.0525C40.3141 60.4152 38.2951 62.3322 35.8066 62.3322Z" fill="url(#paint4_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="35.8068" y1="2" x2="35.8068" y2="121" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="35.2785" y1="12.4846" x2="35.2785" y2="13.4846" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="35.8069" y1="100.031" x2="35.8069" y2="101.031" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="36.4736" y1="106.125" x2="36.4736" y2="115.05" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="35.8066" y1="39.8636" x2="35.8066" y2="72.3182" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>

  </div>
</template>

<script>
export default{
  name:'step-two',
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
