<template>
    <BaseModal>
        <div>
            <h1>You sure about that?</h1>
            <p> This will reset all values to zero and start a new poll.</p>
        </div>
        <div style="border-left: none;" class="grid grid-flow-col auto-cols-auto">
            <div class="button-modal">
                <button style="border-left: none;" @click="confirmCallback">Reset Poll</button>
            </div>
            <div class="button-modal">
                <button @click="cancelCallback" >Nevermind</button>
            </div>
        </div>
    </BaseModal>
    
</template>

<script>

import BaseModal from './BaseModal.vue'

export default {
    name: 'ConfirmResetModal',
    components: { BaseModal },
    props: {
        cancelCallback: {
            type: Function,
            default: () => { alert ('must implement callback') }
        },
        confirmCallback: {
            type: Function,
            default: () => { alert('must implement') }
        }
    },
    methods: {
        cancel() {
            alert('Hey! implement some cancel')
        },
        confirm() {
            alert('Hey!! implement some confirmation logic')
        }
    }
}
</script>

<style scoped>

h1{
    font-size: 24px;
    font-weight: 700;
}

.grid{
    margin-top: 15px;
}

p{
    padding: 0 20px;
}

</style>