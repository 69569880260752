<template>
  <div class="score-container sm:mx-6 lg:mx-12">
      <div class="background counter bg-buttongray monospace-hack">
          <span class="vote-text">{{ votes }}</span>
      </div>
      <div class="background thumbs bg-buttongray sm:my-1 sm:py-12 
            lg:py-16 flex justify-around items-center">
          <slot />
      </div>
      <div class="background caption-text py-2 bg-buttongray" style="">
          {{ caption }}
      </div>
  </div>
</template>

<script>

export default{
  name: 'Scoreboard',
  props: {
      votes: {
          type: String,
          default: '0',
      },
      caption: {
          type: String,
          default: 'Missing Caption'
      }
  },
  data(){
    return{}
  }
}
</script>

<style scoped>

.vote-text {
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 192.638px;
line-height: 289px;
text-align: center;
color: #FFFFFF;
}

.caption-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 96px;
    text-align: center;
    min-width: 307px;
}

.monospace-hack {
    text-transform: full-width; 
}

/*Responsive settings*/

@media (max-height: 469px) { 
    .vote-text{
      font-size: 74px;
      line-height: 120px;
    }
    .caption-text{
        min-width: 0px;
        font-size: 32px;
        line-height: 60px;
    }
    .score-container{
        width: 120px;
        margin: 20px;
    }
    .background{
        margin-bottom: 4px;
    }
    .thumbs{
        height: 170px;
        padding: 40%;
    }
  }

  @media (min-height: 470px) { /*iPhone 5*/
    .vote-text{
      font-size: 74px;
      line-height: 120px;
    }
    .caption-text{
        min-width: 0px;
        font-size: 32px;
        line-height: 60px;
    }
    .score-container{
        width: 120px;
        margin: 20px;
    }
    .background{
        margin-bottom: 4px;
    }
    .thumbs{
        height: 170px;
        padding: 40%;
    }
 }
  @media (min-height: 667px) { /*iPhone 6*/
      .counter {
        height: 150px;
    }
     .vote-text{
      font-size: 86px;
      line-height: 150px;
    }
    .caption-text{
        min-width: 0px;
        font-size: 32px;
        line-height: 60px;
    }
    .score-container{
        width: 150px;
        margin: 20px;
    }
    .background{
        margin-bottom: 4px;
    }
    .thumbs{
        height: 230px;
        padding: 45%;
    }
    
  }

  @media (min-height: 812px) { /*iPad*/
  .counter {
        height: 220px;
    }
     .vote-text{
      font-size: 150px;
      line-height: 220px;
    }
    .caption-text{
        min-width: 0px;
        font-size: 42px;
        line-height: 100px;
    }
    .score-container{
        width: 220px;
        margin: 20px;
    }
    .background{
        margin-bottom: 4px;
    }
    .thumbs{
        height: 320px;
        padding: 45%;
    }
    
  }
</style>