<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #373737;
}

body, html, #app {
  min-height: 100%;
  height: 1px;
  background: #373737;
  color: #959595;
}

#nav {
  padding: 30px;
}

</style>
