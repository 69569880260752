<template>
  <div>
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#reset_filter)">
<path d="M39.5833 16.4583V2.08333L35.625 6.04167C31.875 2.29167 26.6667 0 20.8333 0C9.375 0 0 9.375 0 20.8333C0 32.2917 9.375 41.6667 20.8333 41.6667C26.6667 41.6667 31.875 39.375 35.625 35.625L29.7917 29.7917C27.5 32.0833 24.375 33.5417 21.0417 33.5417C14.1667 33.5417 8.54167 27.9167 8.54167 21.0417C8.54167 14.1667 14.1667 8.54167 21.0417 8.54167C24.5833 8.54167 27.7083 10 29.7917 12.2917L25.4167 16.6667H39.5833V16.4583Z" fill="#959595"/>
</g>
<defs>
<filter id="reset_filter" x="0" y="0" width="39.5833" height="45.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
</filter>
</defs>
</svg>

  </div>
</template>

<script>
export default{
  name:'ResetButton',
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>

@media (max-height: 469px) { 
    svg{
      height: 25px; 
      width: 25px;
    }
  }

@media (min-height: 470px) {
    svg{
      height: 30px; 
      width: 30px;
    }
  }

  @media (min-height: 667px) {
    svg{
      height: 40px; 
      width: 40px;
    }
  }

  @media (min-height: 812px) {
    svg{
      height: 50px; 
      width: 50px;
    }
  }
</style>
