<template>
  <div v-if="!isLoading" class="flex flex-col min-h-full min-w-full items-center justify-around">

    <Logo :scale="0.5"/>
    <!-- <img src="../assets/Images/qr-logo-mini.svg" alt="qr logo"> -->

    <ConfirmResetModal class="reset-modal" v-if="resetModalActive" :confirm-callback="confirmReset" :cancel-callback="cancelReset" />

    <VoteButton caption="Yes" :voted="hasVoted" :votes="upvotes" :click="upvote">
      <ThumbsUpIcon :scale="0.8" :disabled="hasVoted && !upVoted" />
    </VoteButton>

    <VoteButton caption="No" :voted="hasVoted" :votes="downvotes" :click="downvote">
      <ThumbsDownIcon :scale="0.8" :disabled="hasVoted && upVoted"/>
    </VoteButton>

    <ResetButton class="bg-gray-600" :click="reset" />

  </div>
  <div v-if="isLoading" class="flex flex-col min-h-full min-w-full items-center justify-around">
    <LoadingDots style="transform: scale(0.5)" />
  </div>
</template>

<script>

import Logo from '../components/svgs/Logo.vue'
import VoteButton from '../components/mobile/VoteButton.vue'
import ThumbsUpIcon from '../components/svgs/ThumbsUpIcon.vue'
import ThumbsDownIcon from '../components/svgs/ThumbsDownIcon.vue'
import ConfirmResetModal from '../components/modals/ConfirmResetModal.vue'
import ResetButton from "../components/mobile/ResetButton.vue"
import LoadingDots from "@/components/LoadingDots";

import {BEND, BendSocket} from "../services/BendSocket";

let socket;

// import { emitEvent, subscribe, onEvent } from '../services/ThumbSocket'

export default{
  components: {
    LoadingDots,
    Logo,
    VoteButton,
    ThumbsUpIcon,
    ThumbsDownIcon,
    ConfirmResetModal,
    ResetButton
  },
  name: 'Vote',
  data(){
    return{
      connected: false,
      upvotes: null,
      downvotes: null,
      hasVotedOn: null,
      voteType: null,
      resetModalActive: false,
      pollId: this.$route.params.id,
      round: 1
    }
  },
  methods:{
    captureVote(vote) {
      this.voteType = vote
      const data = { vote, round: this.round }
      localStorage.setItem(`qrthumbs-${this.pollId}`, JSON.stringify(data))
    },
    upvote() {
      this.hasVotedOn = this.pollId;
      this.captureVote('up') // XXX Do on receipt of WS confirmation instead?
      this.emitEvent('upvote')
    },
    downvote(){
      this.hasVotedOn = this.pollId;
      this.captureVote('down') // XXX Do on receipt of WS confirmation instead?
      this.emitEvent('downvote')
    },
    reset() {
      this.resetModalActive = true;
    },
    confirmReset() {
      this.emitEvent('reset')
      // XXX Temporary
      localStorage.removeItem(`qrthumbs-${this.pollId}`)
      this.hasVotedOn = null
      this.voteType = null
      this.resetModalActive = false;
    },
    cancelReset() {
      this.resetModalActive = false;
    },
    onOpen() {
      console.log('onOpen')
      socket.logInToAppWithCredentials(BEND.APP_KEY, 'pusher', 'pusher')
    },
    onClose() {
      console.log('onClose')
      this.connected = false
    },
    onMessage(message) {
      // console.log(message)
      if (message.id === 'login' && message.event === 'pusher.success') {
        // logged in successfully, now subscribe to poll channel..
        this.connected = true
        socket.subscribe(this.channel)
      }
      if (message.event === 'current') {
        const data = message.data
        // console.log('round', this.round, 'data.round', data.round)
        if (this.round !== data.round) {
          this.clearVote()
        } else {
          this.upvotes = data.upvotes
          this.downvotes = data.downvotes
        }
        this.round = data.round
        // console.log('round', this.round, 'connected', this.connected, 'upvotes', this.upvotes, 'downvotes', this.downvotes, 'hasVoted', this.hasVotedOn)
      }
    },
    emitEvent(event) {
      socket.push(this.channel, event, {})
    },
    connectToBendSocket() {
      socket = new BendSocket({
        onOpen: () => this.onOpen(),
        onClose: () => this.onClose(),
        onMessage: (message) => this.onMessage(message)
      });
    },
    clearVote() {
      console.log('clearVote')
      localStorage.removeItem(`qrthumbs-${this.pollId}`)
      this.hasVotedOn = null
      this.voteType = null
    }
  },
  computed: {
    hasVoted() {
      return this.hasVotedOn !== null;
    },

    isLoading() {
      // we are loading until we receive an update from the host
      // and these values are no longer null
      return !this.connected || (this.upvotes === null || this.downvotes === null)
    },
    channel() {
      return `poll-${this.$route.params.id}`
    },
    upVoted() {
      return String(this.voteType) === 'up'
    },
  },
  watch: {
    connected(connected) {
      if (!connected) {
        console.log('reconnecting...')
        this.connectToBendSocket()
      }
    }
  },
  mounted() {
    const voteData = localStorage.getItem(`qrthumbs-${this.pollId}`)
    if (voteData) {
      let data = JSON.parse(voteData)
      if (this.round === data.round) {
        this.hasVotedOn = this.pollId
        this.voteType = data.vote
      } else {
        this.clearVote()
      }
    }
    this.connectToBendSocket()
  }
}

</script>

<style scoped>

.reset-modal >>> .modal-card-content {
  /* padding: 0!important; */
  margin-top: 20px !important;
}

.reset-modal >>> .modal-content{
  padding: 0 20px !important;
}
.reset-modal >>> .button-modal{
  border-top: 1px solid black;
  border-left: 0.5px solid black;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

</style>
