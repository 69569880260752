<template>
  <div>
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#step_one)">
<path d="M9.46313 33.1037H33.092V9.47485H9.46313V33.1037ZM14.1889 14.2006H28.3662V28.378H14.1889V14.2006Z" fill="url(#paint0_linear)"/>
<path d="M18.9146 18.9265H23.6403V23.6522H18.9146V18.9265Z" fill="url(#paint1_linear)"/>
<path d="M9.46313 70.91H33.092V47.2811H9.46313V70.91ZM14.1889 52.0069H28.3662V66.1842H14.1889V52.0069Z" fill="url(#paint2_linear)"/>
<path d="M18.9146 56.7327H23.6403V61.4584H18.9146V56.7327Z" fill="url(#paint3_linear)"/>
<path d="M70.8879 9.47485H47.259V33.1037H70.8879V9.47485ZM66.1621 28.378H51.9848V14.2006H66.1621V28.378Z" fill="url(#paint4_linear)"/>
<path d="M56.7104 18.9265H61.4362V23.6522H56.7104V18.9265Z" fill="url(#paint5_linear)"/>
<path d="M80.4454 77.1156L67.6001 64.2703C69.6706 61.5081 70.8983 58.0797 70.8983 54.3699C70.8983 45.2497 63.4784 37.8297 54.3581 37.8297C45.2378 37.8297 37.8179 45.2497 37.8179 54.3699C37.8179 63.4902 45.2378 70.9102 54.3581 70.9102C58.068 70.9102 61.4964 69.6824 64.2585 67.6119L77.1038 80.4572L80.4454 77.1156ZM52.7827 42.6636V52.7945H42.6517C43.3555 47.5384 47.5265 43.3674 52.7827 42.6636ZM54.3579 66.1842C48.9345 66.1842 44.356 62.5096 42.9734 57.5203H57.5085V42.9852C62.4978 44.3678 66.1724 48.9463 66.1724 54.3698C66.1724 60.8843 60.8726 66.1842 54.3579 66.1842Z" fill="url(#paint6_linear)"/>
<path d="M4.73774 4.74976H18.9151V0.0239868H0.0119629V18.8805H4.73774V4.74976Z" fill="url(#paint7_linear)"/>
<path d="M4.76093 61.4585H0.0351562V80.3616H18.8915V75.6358H4.76093V61.4585Z" fill="url(#paint8_linear)"/>
<path d="M75.624 18.9031H80.3497V0H61.4934V4.72578H75.624V18.9031Z" fill="url(#paint9_linear)"/>
<path d="M37.8179 9.47485H42.5436V18.9264H37.8179V9.47485Z" fill="url(#paint10_linear)"/>
<path d="M28.3662 37.8296H37.8178V42.5554H28.3662V37.8296Z" fill="url(#paint11_linear)"/>
<path d="M9.46313 37.8296H23.6405V42.5554H9.46313V37.8296Z" fill="url(#paint12_linear)"/>
<path d="M37.8179 23.6522H42.5436V33.1038H37.8179V23.6522Z" fill="url(#paint13_linear)"/>
</g>
<defs>
<linearGradient id="paint0_linear" x1="21.2776" y1="9.47485" x2="21.2776" y2="33.1037" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="21.2774" y1="18.9265" x2="21.2774" y2="23.6522" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="21.2776" y1="47.2811" x2="21.2776" y2="70.91" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="21.2774" y1="56.7327" x2="21.2774" y2="61.4584" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="59.0735" y1="9.47485" x2="59.0735" y2="33.1037" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="59.0733" y1="18.9265" x2="59.0733" y2="23.6522" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="59.1316" y1="37.8297" x2="59.1316" y2="80.4572" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="9.46352" y1="0.0239868" x2="9.46352" y2="18.8805" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="9.46332" y1="61.4585" x2="9.46332" y2="80.3616" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="70.9216" y1="0" x2="70.9216" y2="18.9031" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint10_linear" x1="40.1808" y1="9.47485" x2="40.1808" y2="18.9264" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint11_linear" x1="33.092" y1="37.8296" x2="33.092" y2="42.5554" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint12_linear" x1="16.5518" y1="37.8296" x2="16.5518" y2="42.5554" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<linearGradient id="paint13_linear" x1="40.1808" y1="23.6522" x2="40.1808" y2="33.1038" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
<clipPath id="step_one">
<rect width="80.4571" height="80.4571" fill="white"/>
</clipPath>
</defs>
</svg>

  </div>
</template>

<script>
export default{
  name: 'step-one',
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
