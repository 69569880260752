<template>
  <div>
  <a @click="click">Share</a>
  </div>
</template>

<script>
export default{
  name:'ShareButton',
  props: {
    click: {
      type: Function,
      default: () => ({})
    }
  },
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
a{
    background: linear-gradient(180deg, #CFCFCF 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    cursor: pointer;
}
</style>
