<template>
  <div :style="{opacity: disabled ? 0.5 : 1}">
    <svg :width="89 * scale" :height="85 * scale" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M86.0553 45.1518C87.5637 46.6603 88.4329 48.7055 88.4329 50.9004C88.4329 54.0669 86.6214 56.8462 83.9225 58.2049C84.4849 59.326 84.7807 60.5641 84.7807 61.857C84.7807 65.027 82.9656 67.8064 80.2666 69.165C81.1029 70.8304 81.3476 72.7478 80.9276 74.6506C80.1096 78.3502 76.6291 81.0309 72.6518 81.0309H39.1284C36.7325 81.0309 32.9708 80.2749 29.998 78.8725V39.7284L30.4362 39.5056L40.9545 16.7196V4.33511C40.9545 3.78009 41.2065 3.25418 41.6374 2.90716C41.6401 2.9052 41.644 2.9022 41.6492 2.89822C41.8763 2.72472 44.5494 0.682983 48.2588 0.682983C52.4844 0.682983 59.2153 7.37007 59.2153 15.2917C59.2153 20.2878 57.4258 26.5074 56.3083 29.9003H78.8678C83.7835 29.9003 87.9653 33.5379 88.3926 38.1799C88.6301 40.7838 87.7609 43.2856 86.0553 45.1518ZM0.780518 46.3351C0.780518 41.3024 4.87827 37.2047 9.91092 37.2047H26.3457V82.8349C24.8154 83.9891 22.9237 84.683 20.8675 84.683H9.91092C4.87827 84.683 0.780518 80.5852 0.780518 75.5526V46.3351Z"/>
</mask>
<g filter="url(#up_filter)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M86.0553 45.1518C87.5637 46.6603 88.4329 48.7055 88.4329 50.9004C88.4329 54.0669 86.6214 56.8462 83.9225 58.2049C84.4849 59.326 84.7807 60.5641 84.7807 61.857C84.7807 65.027 82.9656 67.8064 80.2666 69.165C81.1029 70.8304 81.3476 72.7478 80.9276 74.6506C80.1096 78.3502 76.6291 81.0309 72.6518 81.0309H39.1284C36.7325 81.0309 32.9708 80.2749 29.998 78.8725V39.7284L30.4362 39.5056L40.9545 16.7196V4.33511C40.9545 3.78009 41.2065 3.25418 41.6374 2.90716C41.6401 2.9052 41.644 2.9022 41.6492 2.89822C41.8763 2.72472 44.5494 0.682983 48.2588 0.682983C52.4844 0.682983 59.2153 7.37007 59.2153 15.2917C59.2153 20.2878 57.4258 26.5074 56.3083 29.9003H78.8678C83.7835 29.9003 87.9653 33.5379 88.3926 38.1799C88.6301 40.7838 87.7609 43.2856 86.0553 45.1518ZM0.780518 46.3351C0.780518 41.3024 4.87827 37.2047 9.91092 37.2047H26.3457V82.8349C24.8154 83.9891 22.9237 84.683 20.8675 84.683H9.91092C4.87827 84.683 0.780518 80.5852 0.780518 75.5526V46.3351Z" fill="url(#paint0_linear)"/>
</g>
<defs>
<filter id="up_filter" x="0.780518" y="0.682983" width="87.6524" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
</filter>
<linearGradient id="paint0_linear" x1="44.6067" y1="0.682983" x2="44.6067" y2="84.683" gradientUnits="userSpaceOnUse">
<stop stop-color="#CFCFCF"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>
  </div>
</template>
<script>
export default{
  name: 'ThumbsUpSvg',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
@media (max-height: 469px) {
    svg{
      height: 40px;
      width: 40px;
    }
  }

@media (min-height: 470px) {
    svg{
      height: 60px;
      width: 60px;
    }
  }
  @media (min-height: 667px) {
    svg{
      height: 80px;
      width: 80px;
    }
  }
  @media (min-height: 812px) {
    svg{
      height: 90px;
      width: 90px;
    }
  }
   @media (min-height: 1024px) {
    svg{
      height: 120px;
      width: 120px;
    }
  }
</style>
