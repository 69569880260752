export const BEND = {
    APP_KEY: 'oN79Vr6LTYzazEBQgo3Qag',
    APP_SECRET: 'xcaSgcrfTBWo4gpYDivr5qNZbSrLOF1dt4wOGtSu',
    WEBSOCKET_URL: 'wss://api.bend.io/ws'
};

export class BendSocket {

    constructor(options = {}) {

        const {onOpen, onMessage, onClose} = options;

        this.messageId = 0;
        this.webSocketUrl = BEND.WEBSOCKET_URL;
        this.webSocket = new WebSocket(this.webSocketUrl);

        this.webSocket.onopen = function () {
            if (onOpen) {
                onOpen();
            }
        };

        this.webSocket.onmessage = function (event) {
            if (onMessage) {
                const message = event.data;
                onMessage(JSON.parse(message));
            }
        };

        this.webSocket.onclose = function () {
            if (onClose) {
                onClose();
            }
        };

        this.webSocket.onerror = function () {
            if (onClose) {
                onClose();
            }
        };

    }

    getMessageId() {
        this.messageId += 1;
        return this.messageId + ''
    }

    disconnect() {
        this.webSocket.close();
        console.log('disconnected')
    }

    logInToAppWithToken(appKey, token) {
        var data = {event: "pusher.login", data: {id: "login", appKey, authtoken: token}};
        this.webSocket.send(JSON.stringify(data));
    }

    logInToAppWithCredentials(appKey, username, password) {
        var data = {event: "pusher.login", data: {id: "login", appKey, username, password}};
        this.webSocket.send(JSON.stringify(data));
    }

    push(channel, event, data) {
        this.webSocket.send(JSON.stringify({
            event: "pusher.push",
            data: {
                id: this.getMessageId(),
                event: event,
                channel: channel,
                payload: data
            }
        }));
    }

    subscribe(channel) {
        var data = {
            event: "pusher.subscribe",
            data: {
                id: this.getMessageId(),
                channel: channel
            }
        };
        this.webSocket.send(JSON.stringify(data));
    }

    unsubscribe(channel) {
        var data = {
            event: "pusher.unsubscribe",
            data: {
                id: this.getMessageId(),
                channel: channel
            }
        };
        this.webSocket.send(JSON.stringify(data));
    }

}
