<template>
  <div :class="classes" class="modal-container modal-form-input-enabled">
    <div class="modal-trap" @click="close"/> 
    <div class="modal-content">
      <div class="modal-card">
        <div :style="modalCardContentStyles" class="modal-card-content flex flex-col" style="height: calc(100% - 40px)">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseModal',
  props: {
    classes: {
      type: Object,
      default: () => {}
    },
    modalCardContentStyles: {
      type: Object,
      default: () => {}
    }
  }
}

</script>

<style scoped>

  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 0.8}
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-24px)
    }
    to {
      opacity: 1;
      transform: translateY(0)
    }
  }

  .modal-container {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .modal-trap {
    animation-name: fadeIn;
    animation-duration: .25s;
    z-index: 21;
    position: absolute;
    background: black;
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }

  .modal-content {
    animation: slideDown;
    animation-duration: .25s;
    z-index: 22;
    position: absolute;
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px !important;
  }

  .modal-button-container * svg {
    transform: scale(1.5);
  }

  .modal-card {
    margin: 0 24px;
    background: #404040;
    border-radius: 4px;
    overflow: hidden;
    color: #FFFFFF;

;
  }

  .modal-card-header > svg {
    fill: white;
    margin: 16px;
  }

  .modal-card-content {
    margin: 0px;
  }

  .modal-card-content > h1 {
    font-family: SF Compact Display, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 104%;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .modal-card-content > p {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #979797;
  }

  .modal-card-content > button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

</style>
