<template>
    <BaseModal>
        <div>
            <div class="row flex flex-row justify-between items-center px-6">
                <div class="col1 px-2"></div>
                <div class="col2">
                    <h1 class="share-title py-3">Tell a friend</h1>
                </div>
                <div class="col3">
                    <CloseButton @click="cancelCallback"/>
                </div>
            </div>
            <div class="flex flex-row justify-center">
                <hr>
            </div>

            <div class="share-body row text-left py-3 px-4">
              <p>Find this site useful? We’d really appreciate if you helped us spread the word.</p>
              <p>Have feedback, questions, suggestions? We’d love to hear from you! Tweet us
              <strong>@justshipped</strong> or email us at <strong><a href="mailto:qrthumbs@justshipped.io">qrthumbs@justshipped.io.</a></strong></p>
            </div>

            
            <div class="share-buttons flex flex-col items-center justify-around">
                <button @click="shareTwitter()" class="twitter-button my-2 mx-0"><img class="button-ico" src="../../assets/Images/twitter-icon.svg">Share on Twitter</button>
                <button @click="shareFacebook()" class="facebook-button my-2"><img class="button-ico" src="../../assets/Images/facebook-icon.svg">Share on Facebook</button>
                <button @click="shareLinkedIn()" class="linkedin-button my-2"><img style="height: 22px; fill: wheat" class="button-ico" src="../../assets/Images/linkedin-icon.svg">Share on LinkedIn</button>
            </div>
            <p class="share-footer my-2">QR Thumbs is a <a href="http://justshipped.io/" target="_blank"><strong><u>Just Shipped</u></strong></a> project.<br>Copyright © 2020. All rights reserved</p>
        </div>
        <!-- <div class="grid grid-flow-col auto-cols-auto">

        </div> -->
    </BaseModal>

</template>

<script>

import BaseModal from './BaseModal.vue'
import CloseButton from '../desktop/CloseButton'

export default {
    name: 'ShareModal',
    components: { BaseModal,CloseButton },
    props: {
        cancelCallback: {
            type: Function,
            default: () => { alert ('must implement callback') }
        },
        confirmCallback: {
            type: Function,
            default: () => { alert('must implement') }
        }
    },
    methods: {
        shareTwitter() {
          window.open('https://twitter.com/intent/tweet?url=http://qrthumbs.com&text=QR Thumbs is the fastest way to hold an anonymous poll with friends and co-workers.', '_blank', 'noopener')
        },
        shareFacebook() {
          window.open('https://www.facebook.com/sharer/sharer.php?u=http://qrthumbs.com', '_blank', 'noopener')
        },
        shareLinkedIn() {
          window.open('https://www.linkedin.com/shareArticle?mini=true&url=http://qrthumbs.com&title=&summary=QR Thumbs is the fastest way to hold an anonymous poll with friends and co-workers.&source=', '_blank', 'noopener')
        }
    }
}
</script>

<style scoped>

h1{
    font-size: 24px;
    font-weight: 700;
}

.grid{
    margin-top: 15px;
}

p{
    padding: 5px 20px;
}

.facebook-button{
  background: #4669AD;
  border-radius: 4px;
  width: 230px;
  height: 42px;
}

.twitter-button{
  background:  #43A1EC;
  border-radius: 4px;
  width: 230px;
  height: 42px;
}

.linkedin-button {
  border-radius: 4px;
  width: 230px;
  height: 42px;
 background: #0e76a8;
}

hr{
  border: 1px solid #D3D3D3;
  width: 90%;
}

.button-ico{
  display: inline;
  padding-right: 5px;
  padding-bottom: 3px;
}

/* Responsive settings*/
@media (max-height: 568px) { /*iPhone 5*/
   .share-body>h1{
      font-size: 12px !important;
    }
  .share-body> p{
    font-size: 9px;
    padding: 0px 0px;
  }
  
  .share-footer{
    font-size: 7px;
    padding: 0px 0px;
  }
  .facebook-button{
    font-size: 9px;
    width: 150px;
    height: 30px;
  }

  .twitter-button{
    font-size: 9px;
    width: 150px;
    height: 30px;
  }

  .linkedin-button {
    font-size: 9px;
    width: 150px;
    height: 30px;
  }
  .button-ico{
  height: 15px !important;
  }

}


  @media (max-height: 667px) { /*iPhone 6*/
   h1{
      font-size: 14px;
    }
  .share-body> p{
    font-size: 10px;
    padding: 0px 0px;
  }
  
  .share-footer{
    font-size: 9px;
    padding: 0px 0px;
  }
  .facebook-button{
    font-size: 10px;
    width: 150px;
    height: 30px;
  }

  .twitter-button{
    font-size: 10px;
    width: 150px;
    height: 30px;
  }

  .linkedin-button {
    font-size: 10px;
    width: 150px;
    height: 30px;
  }
  .button-ico{
  height: 17px !important;
  }

} 

  @media (max-height: 812px) { /*iPhone X*/
   h1{
      font-size: 16px;
    }
  .share-body> p{
    font-size: 14px;
    padding: 0px 0px;
  }
  
  .share-footer{
    font-size: 12px;
    padding: 0px 0px;
  }
  .facebook-button{
    font-size: 12px;
    width: 170px;
    height: 30px;
  }

  .twitter-button{
    font-size: 12px;
    width: 170px;
    height: 30px;
  }

  .linkedin-button {
    font-size: 12px;
    width: 170px;
    height: 30px;
  }
  .button-ico{
  height: 18px !important;
  }

} 

</style>
