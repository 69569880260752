<template>
    <BaseModal>
        <div>
            <div class="row flex flex-row justify-between items-center px-6">
                <div class="col1 px-2"></div>
                <div class="col2">
                    <h1 class="about-title py-3">About QR Thumbs</h1>
                </div>
                <div class="col3">
                    <CloseButton @click="cancelCallback"/>
                </div>
            </div>
            <div class="flex flex-row justify-center">
                <hr>
            </div>
            <div class="about-copy row text-left py-3 px-4">
              <h2>What is this?</h2>
              <p>QR Thumbs lets you host real-time anonymous polls where everyone can vote directly from their phone</p>
              <h2>That's cool, but how do I use it?</h2>
              <ol>
                <li>1. Press the "Start a session" button from a desktop computer</li>
                <li>2. Voters scan the on screen QR code with their phone camera.</li>
                <li>3. The voters will be brought to a voting screen on their phone.</li>
                <li>4. As votes come in, the host will live update with results.</li>
              </ol>
              <h2>Who made this?</h2>
              <p>This is a JustShipped project created by <a href="https://www.linkedin.com/in/shu-li-design/">Shu Li</a>,  <a href="http://codymikol.com">Cody Mikol</a>, <a href="http://kostasnasis.com">Kostas Nasis</a>, and <a href="https://www.linkedin.com/in/rubenmso/">Rubén Muñoz Solera</a></p>
              <h2>Questions? Suggestions?</h2>
              <p>Email us at <a href="mailto:qrthumbs@justshipped.io">qrthumbs@justshipped.io</a>. We'd love to hear from you.</p>
            </div>
            <p class="footer-copy my-2">QR Thumbs is a <a href="http://justshipped.io/" target="_blank"><strong><u>Just Shipped</u></strong></a> project.<br>Copyright © 2020. All rights reserved</p>
        </div>
        <!-- <div class="grid grid-flow-col auto-cols-auto">

        </div> -->
    </BaseModal>

</template>

<script>

import BaseModal from './BaseModal.vue'
import CloseButton from '../desktop/CloseButton'

export default {
    name: 'AboutModal',
    components: { BaseModal, CloseButton },
    props: {
        cancelCallback: {
            type: Function,
            default: () => { alert ('must implement callback') }
        },
        confirmCallback: {
            type: Function,
            default: () => { alert('must implement') }
        }
    },
    methods: {
        cancel() {
            alert('Hey! implement some cancel')
        },
        confirm() {
            alert('Hey!! implement some confirmation logic')
        }
    }
}
</script>

<style scoped>

h1{
    font-size: 24px;
    font-weight: 700;
}

h1, h2, p, ol{
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

h2 {
  margin-top: 4px;
  margin-bottom: 4px;
}

p{
    padding: 5px 20px;
}

hr{
  border: 1px solid #D3D3D3;
  width: 90%;
}

.about-copy > h2 {
  font-size: 18px;
  font-weight: bold;
}

.about-copy > ol {
  padding-bottom: 6px;
}

.about-copy > ol > li {
  padding-bottom: 4px;
}

a {
  text-decoration: underline;
}

a:hover {
  color: #e3e3e3;
}

/* Responsive settings*/

  @media (max-height: 667px) { /*iPhone 6*/
   h1{
      font-size: 14px;
    }
  .about-copy> p{
    font-size: 9px;
    padding: 0px 0px;
  }
  .about-copy > h2 {
  font-size: 12px;
  font-weight: bold;
  padding: 0px 0px;
  }
  .about-copy > ol{
    font-size: 9px;
    padding: 0px 0px;
  }
  .footer-copy{
    font-size: 8px;
    padding: 0px 0px;
  }

} 

  @media (max-height: 812px) { /*iPhone X*/
   h1{
      font-size: 16px;
    }
  .about-copy> p{
    font-size: 10px;
    padding: 0px 0px;
  }
  .about-copy > h2 {
  font-size: 12px;
  font-weight: bold;
  padding: 0px 0px;
  }
  .about-copy > ol{
    font-size: 10px;
    padding: 0px 0px;
  }
  .footer-copy{
    font-size: 9px;
    padding: 0px 0px;
  }

} 

</style>
